import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { TUser } from "./UserModel";

export type TRoute = {
  id: string;
  type?: "route" | "folder";
  depth?: number;
  parentId?: string | undefined;
  hasChildren?: boolean;
  title?: string | undefined;
  url?: string | undefined;
  page?: string | undefined;
  icon?: string;
  children?: TRoute[];
  menuType?: "fixed" | undefined;
};

const routeState = atomFamily<TRoute, TRoute["id"]>({
  key: "routeState",
  default: undefined,
});

const currentRouteState = atom<TRoute>({
  key: "currentRouteState",
  default: undefined,
});

export type TRouteIds = TRoute["id"][];
const routeIdsState = atom<TRouteIds>({
  key: "routeIdsState",
  default: [],
});

export const allRoutesSelector = selector<TRoute[]>({
  key: "allRoutesSelector",
  get: ({ get }) => {
    const ids = get(routeIdsState);
    return ids.map((routeId) => get(routeState(routeId)));
  },
});

export const useInitRoutes = () => {
  const initRoutes = useRecoilCallback(({ set }) => (user: TUser) => {
    const routesBase = user?.routes.filter((r: TRoute) => r.id !== "1"); // [暫定][暫定処置]
    // [ToDo] ↓ 最終的にはサーバから取得したい
    const fixedRoutes: TRoute[] = [
      {
        id: "1",
        icon: "Home",
        page: "Home",
        title: "Home",
        type: "route",
        url: "/",
        menuType: "fixed",
      },
    ];
    const routes = !routesBase || routesBase.length === 0 ? fixedRoutes : [...fixedRoutes, ...routesBase];
    registRoutes(routes);
    const currentRoute = routes ? routes.find((route) => route.url === window.location.pathname) : undefined;
    currentRoute && set(currentRouteState, currentRoute);
    return routes;
  });

  const registRoutes = useRecoilCallback(({ set }) => (routes: TRoute[]) => {
    const routeIds: TRouteIds = [];
    routes.forEach((route: TRoute) => registRoute({ route, routeIds }));
    set(routeIdsState, routeIds);
  });

  type TArgs = {
    route: TRoute;
    routeIds: TRouteIds;
  };
  const registRoute = useRecoilCallback(({ set }) => ({ route, routeIds }: TArgs) => {
    set(routeState(route.id), route);
    routeIds.push(route.id);
  });

  return initRoutes;
};

export const useRouteStates = () => {
  const allRoutes = useRecoilValue(allRoutesSelector);
  const routeIds = useRecoilValue(routeIdsState);
  const currentRoute = useRecoilValue(currentRouteState);
  const setCurrentRoute = useSetRecoilState(currentRouteState);

  const setRoute = useRecoilCallback(({ set }) => (route: TRoute) => {
    set(routeState(route.id), route);
  });

  const setRouteIds = useRecoilCallback(({ set }) => (routeIds: TRoute["id"][]) => {
    set(routeIdsState, routeIds);
  });

  return { allRoutes, routeIds, currentRoute, setRoute, setRouteIds, setCurrentRoute };
};
