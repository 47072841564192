export const drawerWidth = 260;
export const editModeDrawerWidth = 400;

export const getServersideSettings = () => {
  const settings = {
    // baseUrl: "https://cockpit.api.cips.jp/",
    // baseUrl: "https://poc.webfocus.cips.jp/drupal/",
    schema: "https",
    host: "poc.webfocus.cips.jp",
    directory: "cockpit",
    baseUrl: "https://poc.webfocus.cips.jp/cockpit/",
  };

  const baseUrl = `${settings.schema}://${settings.host}/${settings.directory}/`;

  return { serversideSettings: settings, baseUrl };
};
