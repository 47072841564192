import { Button, FormControl, FormGroup, FormLabel, Link, TextField, Typography } from "@mui/material";
import StdFormDialog from "components/common/form/StdFormDialog";
import Icons from "components/common/Icons";
import {
  useLeftNavItems,
  useMenuEditFormMode,
  useMenuEditFormOpen,
  useMenuEditFormRoute,
  useSetMenuEditFormOpen,
} from "globalStates/leftNavState";
import { defaultLayouts, useCardLayouts } from "models/CardLayoutModel";
import { TRoute, useRouteStates } from "models/RouteModel";
import { useUser } from "models/UserModel";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const menuIcons = ["Dashboard", "Article", "Inbox"];

type TType = "route" | "folder";
const MenuEditFormDialog = () => {
  const [typeState, setType] = useState<TType>("route");
  const [iconState, setIcon] = useState<string>("Dashboard");
  const open = useMenuEditFormOpen();
  const mode = useMenuEditFormMode();
  const setOpen = useSetMenuEditFormOpen();
  const route = useMenuEditFormRoute(mode, typeState);
  const { routeIds, setRoute, setRouteIds, setCurrentRoute } = useRouteStates();
  const { getAllCardLayouts } = useCardLayouts();
  const { patchUser } = useUser();
  const { allItems, resetItemIds } = useLeftNavItems();
  const navigate = useNavigate();

  const handleOnClose = useCallback(() => {
    setType("route");
    setIcon("Dashboard");
    setOpen(false);
  }, [setOpen]);

  const save = useCallback(
    async (newRoute: TRoute) => {
      const newRoutes = [...allItems];
      newRoutes.push(newRoute);
      const newLayouts = [...(await getAllCardLayouts())];
      newLayouts.push({
        route_id: newRoute.id,
        layouts: defaultLayouts,
      });
      await patchUser({ routes: newRoutes, layouts: newLayouts });
    },
    [allItems, getAllCardLayouts, patchUser],
  );

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const newRoute = { ...route };
      newRoute.title = String(data.get("title"));
      if (typeState === "route") newRoute.icon = iconState;
      setRoute(newRoute);
      if (mode === "add") {
        const newRouteIds = [...routeIds];
        newRouteIds.push(newRoute.id);
        setRouteIds(newRouteIds);
        resetItemIds();
        await save(newRoute);
      }
      handleOnClose();
      newRoute.url && navigate(newRoute.url);
      setCurrentRoute(newRoute);
    },
    [
      handleOnClose,
      iconState,
      mode,
      navigate,
      resetItemIds,
      route,
      routeIds,
      save,
      setCurrentRoute,
      setRoute,
      setRouteIds,
      typeState,
    ],
  );

  const handleOnIcon = useCallback((icon: any) => setIcon(icon), []);

  const handleOnType = useCallback((type: TType) => setType(type), []);

  useEffect(() => {
    route && route.icon && setIcon(String(route.icon));
  }, [route]);

  if (!route) return <></>;

  return (
    <StdFormDialog
      title="Edit Menu Form"
      openState={open}
      handleOnSubmit={handleOnSubmit}
      handleOnClose={handleOnClose}
    >
      {mode === "add" && (
        <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2 }}>
          <FormLabel component="legend">追加したいもの</FormLabel>
          <FormGroup row>
            <Button
              variant={typeState === "route" ? "contained" : "outlined"}
              onClick={() => handleOnType("route")}
              sx={{ m: 1 }}
            >
              ページ
            </Button>
            <Button
              variant={typeState === "folder" ? "contained" : "outlined"}
              onClick={() => handleOnType("folder")}
              sx={{ m: 1 }}
            >
              フォルダ
            </Button>
          </FormGroup>
        </FormControl>
      )}
      <TextField
        margin="normal"
        required
        fullWidth
        id="title"
        label="Menu Title"
        name="title"
        autoFocus
        defaultValue={mode === "edit" ? route.title : ""}
      />
      {typeState === "route" && route.type !== "folder" && (
        <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2 }}>
          <FormLabel component="legend">Icon</FormLabel>
          <FormGroup row>
            {menuIcons.map((i) => (
              <Button
                key={i}
                variant={i === iconState ? "contained" : "outlined"}
                onClick={() => handleOnIcon(i)}
                sx={{ m: 1 }}
              >
                <Icons iconName={i} />
              </Button>
            ))}
          </FormGroup>
          <Typography variant="body2" sx={{ margin: 2, marginLeft: 5, color: "grey.800" }}>
            他に欲しいアイコンが有ったら、
            <br />
            <Link href="https://mui.com/material-ui/material-icons/#search-material-icons" target="_blank">
              https://mui.com/material-ui/material-icons/#search-material-icons
            </Link>
            <br />
            で探して、津森まで連絡下さい。
          </Typography>
        </FormControl>
      )}
    </StdFormDialog>
  );
};

export default MenuEditFormDialog;
