import { Button, List, ListItem, SxProps } from "@mui/material";
import Icons from "components/common/Icons";
import MenuDeleteFormDialog from "components/form/MenuDeleteFormDialog";
import MenuEditFormDialog from "components/form/MenuEditFormDialog";
import {
  useLeftNavIsEditMode,
  useLeftNavItems,
  useSetLeftNavIsEditMode,
  useSetMenuEditFormMode,
  useSetMenuEditFormOpen,
} from "globalStates/leftNavState";
import { useCardLayouts } from "models/CardLayoutModel";
import { useInitRoutes } from "models/RouteModel";
import { useUser } from "models/UserModel";
import { useCallback } from "react";

const sxListItem: SxProps = { padding: 0 };
const sxButton: SxProps = { width: "100%", borderRadius: 0 };
const sxButtonGrey: SxProps = { ...sxButton, color: "grey.400", "&:hover": { color: "grey.600" } };

const LeftNavBottom = () => {
  const setEditMode = useSetLeftNavIsEditMode();
  const isEditMode = useLeftNavIsEditMode();
  const { allItems, resetItemIds } = useLeftNavItems();
  const { user, patchUser } = useUser();
  const initRoutes = useInitRoutes();
  const setEditFormOpen = useSetMenuEditFormOpen();
  const setMode = useSetMenuEditFormMode();
  const { getAllCardLayouts } = useCardLayouts();

  const save = useCallback(async () => {
    const layouts = await getAllCardLayouts();
    await patchUser({ routes: allItems, layouts });
  }, [allItems, getAllCardLayouts, patchUser]);

  const handleOnAdd = useCallback(() => {
    setMode("add");
    setEditMode(false);
    setEditFormOpen(true);
  }, [setEditFormOpen, setEditMode, setMode]);

  const handleOnEditOpen = useCallback(() => {
    setMode("edit");
    setEditMode(true);
  }, [setEditMode, setMode]);

  const handleOnEditCancel = useCallback(async () => {
    initRoutes(user);
    await resetItemIds();
    setEditMode(false);
  }, [initRoutes, resetItemIds, setEditMode, user]);

  const handleOnEditSave = useCallback(() => {
    save();
    setEditMode(false);
  }, [save, setEditMode]);

  return (
    <>
      <List
        sx={{
          padding: 0,
          backgroundColor: "grey.100",
          display: "flex",
        }}
      >
        {isEditMode ? (
          <>
            <ListItem sx={sxListItem}>
              <Button variant="contained" color="warning" sx={sxButton} onClick={handleOnEditCancel}>
                Cancel
              </Button>
            </ListItem>

            <ListItem sx={sxListItem}>
              <Button type="submit" variant="contained" sx={sxButton} onClick={handleOnEditSave}>
                Save
              </Button>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem sx={sxListItem}>
              <Button onClick={handleOnAdd} sx={sxButtonGrey}>
                　<Icons iconName="Add" />　
              </Button>
            </ListItem>
            <ListItem sx={sxListItem}>
              <Button onClick={handleOnEditOpen} sx={sxButtonGrey}>
                　<Icons iconName="Edit" />　
              </Button>
            </ListItem>
          </>
        )}
      </List>
      <MenuEditFormDialog />
      <MenuDeleteFormDialog />
    </>
  );
};

export default LeftNavBottom;
