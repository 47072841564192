import { createElement, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { useRouteStates } from "models/RouteModel";
import MainLayout from "../components/layout/MainLayout";
import Page404 from "../components/pages/Page404";

const Router = () => {
  const { allRoutes } = useRouteStates();

  return !allRoutes ? (
    <></>
  ) : (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {allRoutes.map((routeItem) => (
          <Route
            key={routeItem.id}
            path={routeItem.url}
            element={createElement(
              lazy(() => import("components/pages/" + routeItem.page)),
              { routeId: routeItem.id },
            )}
          />
        ))}
      </Route>
      <Route key="404" path="*" element={<MainLayout />}>
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
};

export default Router;
