import { Box, ListItemButton, ListItemIcon, ListItemText, SxProps, useTheme } from "@mui/material";
import Icons from "components/common/Icons";
import { TRoute, useRouteStates } from "models/RouteModel";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DragHandle from "@mui/icons-material/DragHandle";
import { DraggableAttributes, DraggableSyntheticListeners } from "@dnd-kit/core";
import {
  useLeftNavIsEditMode,
  useLeftNavItems,
  useLeftNavOpenFolders,
  useSetMenuDeleteFormOpen,
  useSetMenuDeleteFormRoute,
  useSetMenuEditFormOpen,
  useSetMenuEditFormRoute,
} from "globalStates/leftNavState";

type TProps = {
  item: TRoute;
  isOverlay?: boolean;
  dragHandleProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
  };
};
const DndLeftNavItem: FC<TProps> = ({ item, isOverlay, dragHandleProps }) => {
  const theme = useTheme();
  const buttonSx: SxProps = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.action.active,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  };

  const navigate = useNavigate();
  const { setCurrentRoute } = useRouteStates();

  const depth = item.depth ? item.depth : 0;

  const { setClose, isOpen: isOpenParent } = useLeftNavOpenFolders();
  const { setItemsOnCollapse } = useLeftNavItems();
  const isOpen = isOpenParent(item.id);
  const setEditFormOpen = useSetMenuEditFormOpen();
  const setEditFormRoute = useSetMenuEditFormRoute();
  const setDeleteFormOpen = useSetMenuDeleteFormOpen();
  const setDeleteFormRoute = useSetMenuDeleteFormRoute();

  const isEditMode = useLeftNavIsEditMode();

  const onClickItem = useCallback(
    (item: TRoute) => {
      if (item.type === "folder") {
        isOpen ? setClose(item.id) : setItemsOnCollapse(item.id);
      } else {
        item.url && navigate(item.url);
        setCurrentRoute(item);
      }
    },
    [isOpen, navigate, setClose, setCurrentRoute, setItemsOnCollapse],
  );

  const handleOnEdit = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setEditFormOpen(true);
      setEditFormRoute(item);
    },
    [item, setEditFormOpen, setEditFormRoute],
  );

  const handleOnDelete = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setDeleteFormOpen(true);
      setDeleteFormRoute(item);
    },
    [item, setDeleteFormOpen, setDeleteFormRoute],
  );

  const getIconName = useCallback(() => {
    let name = "";
    if (item.type === "folder") name = isOpen ? "FolderOpen" : "Folder";
    else if (item.icon) name = item.icon;
    return name;
  }, [isOpen, item.icon, item.type]);

  const hoge = item.id.slice(0, 4);

  return (
    <ListItemButton dense sx={{ pl: (depth + 1) * 2 }} onClick={() => onClickItem(item)}>
      <ListItemIcon>
        <Icons iconName={getIconName()} />
      </ListItemIcon>
      <ListItemText primary={`${item.title}|${hoge}`} />
      {item.type === "folder" && (isOpen ? <Icons iconName="ExpandLess" /> : <Icons iconName="ExpandMore" />)}
      {isEditMode && (
        <>
          {!isOverlay && (
            <>
              <Box sx={buttonSx} onClick={handleOnEdit}>
                <Icons iconName="EditOutlined" />
              </Box>
              <Box sx={buttonSx} onClick={handleOnDelete}>
                <Icons iconName="DeleteForever" />
              </Box>
            </>
          )}
          <Box
            ref={dragHandleProps?.ref}
            {...dragHandleProps?.attributes}
            {...dragHandleProps?.listeners}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: dragHandleProps ? "grab" : "grabbing",
              color: theme.palette.action.active,
              "&:hover": {
                color: theme.palette.text.primary,
              },
            }}
          >
            <DragHandle />
          </Box>
        </>
      )}
    </ListItemButton>
  );
};

export default DndLeftNavItem;
