import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { apiFetchUser, apiPatchUser, apiUploadUserAvatar } from "modules/Drupal/Drupal";

type TUser = {
  uid: number;
  menus: [];
  routes: [];
  cards: [];
  layouts: {};
  firstName: string;
  lastName: string;
  avatar_file_id: number;
  avatar_url: string;
} | null;

const useUser = () => {
  const { data: fetchedUserData, refetch } = useSuspenseQuery({
    queryKey: ["userData"],
    queryFn: apiFetchUser,
  });

  const getUser = () => (fetchedUserData?.status === "success" ? fetchedUserData.user : null);
  const user = fetchedUserData?.status === "success" ? fetchedUserData.user : null;

  const patchUser = async (newData: any) => {
    const result = await apiPatchUser(fetchedUserData?.raw, newData);
    refetch();
    return result;
  };

  const uploadUserAvatar = async (file: File) => {
    const result: any = await apiUploadUserAvatar(file);
    return result;
  };

  return { user, getUser, patchUser, refetchUser: refetch, uploadUserAvatar };
};

const useResetUser = () => {
  const queryClient = useQueryClient();
  const resetUser = () => {
    queryClient.clear();
  };
  return resetUser;
};

export type { TUser };

export { useUser, useResetUser };
